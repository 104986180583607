import React, { useState, useEffect, useMemo } from 'react';
import { isProjectMappingInHelpCenterEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import { ThemedButton } from 'view/common/themed-button';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import Heading from '@atlaskit/heading';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import { ThirdPartyCardConnector } from '@atlassian/third-party-connectors/src/ui/index';
import { ExperienceSuccess } from '@atlassian/ufo';
import { ArticleItem } from '../article-item';
import {
    sendSearchOperationalAnalyticEvents,
    sendSearchUIAnalyticEvents,
    ResultList,
    ARTICLES_RESULT_LIMIT,
    INITIAL_ARTICLES_LIMIT,
    useQueryParams,
    getIdsArray,
    isSinglePortalSearch,
} from '../common';
import { csmSearchExperience, searchExperience } from '../experiences';
import { SearchResultNone } from '../none';
import type {
    articlesSearchQuery,
    HelpObjectStoreArticleSourceSystem,
} from './__generated__/articlesSearchQuery.graphql';
import messages from './messages';

export interface Props {
    term: string;
    updateResultsCount: (count: number, acknowledgement?: number) => void;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const Articles = ({ term, updateResultsCount, createAnalyticsEvent }: Props) => {
    di(getEnv, isCSMHelpCenter);
    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const CSMPortalId = isCSM ? getEnv().hoistedPortalId : undefined;
    const { portalIds, categoryIds } = useQueryParams() as {
        portalIds: string[];
        categoryIds: string[];
    };
    const [portalId] = usePathParam('portalId');

    const getSelectedPortalIds = () => {
        if (CSMPortalId) return getIdsArray(CSMPortalId);
        if (isSinglePortalSearch(portalId)) return getIdsArray(portalId);
        return getIdsArray(portalIds);
    };

    const cloudId = getCloudId();
    const { formatMessage } = useIntl();
    const data = useLazyLoadQuery<articlesSearchQuery>(
        graphql`
            query articlesSearchQuery(
                $cloudId: ID!
                $queryTerm: String!
                $limit: Int!
                $portalIds: [String!]
                $categoryIds: [String!]
                $helpCenterAri: ID
                $highlight: Boolean
                $isSourceSystemEnabled: Boolean!
            ) {
                helpObjectStore_searchArticles(
                    cloudId: $cloudId
                    queryTerm: $queryTerm
                    limit: $limit
                    portalIds: $portalIds
                    categoryIds: $categoryIds
                    helpCenterAri: $helpCenterAri
                    highlight: $highlight
                ) {
                    __typename
                    ... on HelpObjectStoreArticleSearchResults {
                        results {
                            metadata {
                                searchStrategy
                                isExternal
                            }
                            sourceSystem @include(if: $isSourceSystemEnabled)
                            ...articleItemFragmentV2 @arguments(isSourceSystemEnabled: $isSourceSystemEnabled)
                        }
                    }
                    ... on HelpObjectStoreSearchError {
                        message
                        extensions {
                            statusCode
                            errorType
                        }
                    }
                }
            }
        `,
        {
            cloudId,
            categoryIds,
            queryTerm: term,
            portalIds: getSelectedPortalIds(),
            helpCenterAri: isProjectMappingInHelpCenterEnabled() ? getHelpCenterAri() : undefined,
            limit: ARTICLES_RESULT_LIMIT,
            highlight: true,
            isSourceSystemEnabled:
                FeatureGates.checkGate('3p-integration-enabled_t5ysh') ||
                FeatureGates.checkGate('kb-cross-site-enabled'),
        }
    );

    const [numberOfResultsShown, setNumberOfResultsShown] = useState(INITIAL_ARTICLES_LIMIT);
    const articles = useMemo(
        () =>
            data.helpObjectStore_searchArticles?.__typename === 'HelpObjectStoreArticleSearchResults'
                ? data.helpObjectStore_searchArticles.results
                : [],
        [data.helpObjectStore_searchArticles]
    );

    const numOfResults = articles.length;
    const hasMoreResults = numOfResults > numberOfResultsShown;
    const showMoreArticlesButton = numberOfResultsShown < 30 && hasMoreResults;
    const slicedArticles = articles.slice(0, numberOfResultsShown) || [];
    const showSection = numOfResults > 0;

    const sourceSystem = useMemo(
        () =>
            articles.reduce(
                (records, item) => {
                    const currentSourceSystem = item.sourceSystem;
                    const value = currentSourceSystem ? (records[currentSourceSystem] ?? 0) : 0;
                    return {
                        ...records,
                        ...(currentSourceSystem && {
                            [currentSourceSystem]: value + 1,
                        }),
                    };
                },
                {} as Record<HelpObjectStoreArticleSourceSystem, number>
            ),
        [articles]
    );

    useEffect(() => {
        if (data.helpObjectStore_searchArticles?.__typename === 'HelpObjectStoreArticleSearchResults') {
            let externalResourcesCount = 0;
            let firstPartySearchPlatformResourcesCount = 0;

            data.helpObjectStore_searchArticles.results.forEach((article) => {
                if (article) {
                    const { metadata } = article;
                    const { isExternal, searchStrategy } = metadata;

                    if (isExternal) {
                        externalResourcesCount += 1;
                    }
                    if (searchStrategy === 'CONTENT_SEARCH') {
                        firstPartySearchPlatformResourcesCount += 1;
                    }
                }
            });

            sendSearchOperationalAnalyticEvents(
                {
                    sourceSystem,
                    action: portalId ? 'KBArticleSinglePortalSearch success' : 'KBArticleHomePageSearch success',
                },
                createAnalyticsEvent,
                portalId
            );

            if (showSection) {
                sendSearchUIAnalyticEvents(
                    {
                        sourceSystem,
                        externalResourcesCount,
                        firstPartySearchPlatformResourcesCount,
                        action: 'rendered',
                        resourceType: 'article',
                        resourceCount: data.helpObjectStore_searchArticles.results.length,
                    },
                    createAnalyticsEvent,
                    portalId
                );
            }
        }
    }, [showSection, createAnalyticsEvent, portalId, sourceSystem, data]);

    useEffect(() => {
        if (data.helpObjectStore_searchArticles?.__typename === 'HelpObjectStoreSearchError') {
            const { extensions, message: errorMessage } = data.helpObjectStore_searchArticles;

            sendSearchOperationalAnalyticEvents(
                {
                    errorMessage,
                    action: portalId ? 'KBArticleSinglePortalSearch failed' : 'KBArticleHomePageSearch failed',
                    statusCode: extensions.length > 0 ? extensions[0].statusCode : undefined,
                    errorType: extensions.length > 0 ? extensions[0].errorType : undefined,
                },
                createAnalyticsEvent,
                portalId
            );
        }
    }, [createAnalyticsEvent, data, portalId]);

    useEffect(() => {
        updateResultsCount(slicedArticles.length, 1);
    }, [term, slicedArticles.length, updateResultsCount]);

    const onShowMoreClick = () => {
        if (numberOfResultsShown < 30) {
            setNumberOfResultsShown(ARTICLES_RESULT_LIMIT);
        }
    };

    if (!showSection) {
        return (
            <Box>
                <SearchResultNone term={term} />
            </Box>
        );
    }

    return (
        <Box>
            <Heading size="xxsmall">{formatMessage(messages.articles)}</Heading>
            {fg('3p-integration-enabled_t5ysh') && (
                <Box xcss={ConnectorContainerStyle}>
                    <ThirdPartyCardConnector type="card" connectors={['googleDrive', 'sharepoint']} />
                </Box>
            )}
            <ResultList role="list">
                {slicedArticles.map((resource, index) => {
                    const itemOrder = index + 1;
                    return (
                        <li key={itemOrder}>
                            <ArticleItem itemOrder={itemOrder} term={term} result={resource} />
                        </li>
                    );
                })}
            </ResultList>
            {showMoreArticlesButton && (
                <Inline alignBlock="center" alignInline="center">
                    <ThemedButton
                        data-test-id="show-more-button"
                        appearance="link"
                        actionSubjectId="showMoreButton"
                        iconAfter={<ChevronDownIcon label={formatMessage(messages.showMoreIcon)} />}
                        onClick={onShowMoreClick}
                    >
                        {formatMessage(messages.showMore)}
                    </ThemedButton>
                </Inline>
            )}
            {isCSM && <ExperienceSuccess experience={csmSearchExperience} />}
            <ExperienceSuccess experience={searchExperience} />
        </Box>
    );
};

const ConnectorContainerStyle = xcss({
    margin: 'space.100',
});

export default withAnalyticsEvents()(Articles);
